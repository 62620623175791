body {
    margin: 8px;
    min-width: 640px;
}

h1 {
    font-size: 24px;

    img {
        width: 24px;
        position: relative;
        top: 4px;
        margin-right: 6px;
    }
}

.ui.menu {
    .item {
        /*.ui.button {
            padding: 6px;
        }*/

        /*.ui.selection.dropdown {
            padding: 4px 24px 4px 1em;
            min-height: 0;
        }*/

        span {
            padding: 0 8px 0 0;
        }
    }

    .ui.dropdown.compressed .menu > .item {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
}

.ui.progress {
    opacity: 0.6;

    .bar {
        height: 13px;
        min-width: 20px;

        > .progress {
            font-size: 10px;
        }
    }
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

table.log {
    border-spacing: 0;
    border-collapse: collapse;

    font-size: 12px;
    font-weight: bold;
    font-family: 'Courier New', serif;

    tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    th,
    td {
        padding: 2px 16px;
    }

    td {
        text-align: right;
    }
}

/*.dashboard {
    !*border: 2px solid blue;*!
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 32px;
}*/

.chart {
    &.line {
        > div.single {
            height: 750px;
        }

        > div.primary {
            height: 600px;
        }

        > div.secondary {
            height: 150px;
        }
    }

    &.bar {
        > div.single {
            height: 750px;
        }

        > div.primary {
            height: 450px;
        }

        > div.secondary {
            height: 150px;
        }
    }
}

.header.tiny {
    > span:first-child {
        margin-left: 6px;
    }

    > span {
        font-weight: normal;
        margin: 0 2px;
    }
}

.ui.table.gauge {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;

    th,
    td {
        padding: 6px 4px;
    }

    tr.hidden {
        opacity: 0;
    }

    th {
        padding-right: 8px;
        text-align: right;
    }

    td.data-col {
        padding-right: 4px;
    }

    td.second-col {
        padding-left: 12px;
        padding-right: 4px;
    }

    td.unit {
        font-size: 12px;
        line-height: 16px;
        vertical-align: bottom;
        text-align: left;
        color: #808080;
    }
}

.table-wrapper {
    position: absolute;
    margin-top: 42px;
}

// medium+ screen sizes
@media (min-width: 992px) {
    .mobile-only {
        display: none !important;
    }
}

// small screen sizes
@media (max-width: 991px) {
    .desktop-only {
        display: none !important;
    }

    .ui.menu .item {
        > .input input {
            width: 104px;
            padding-left: 4px;
            padding-right: 0;
        }
    }
}
